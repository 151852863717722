import { Store, StoreConfig } from '@datorama/akita';
import { IUser } from './user.model';

export interface IUserState {
  userDetails: IUser;
}

export function createInitialState(): IUserState {
  return {
    userDetails: null,
  };
}

@StoreConfig({ name: 'user' })
export class UserStore extends Store<IUserState> {

  constructor() {
    super(createInitialState());
  }

}
