import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IOrder, OrdersQuery, OrdersService } from '../_services/ordersState';
import { internalStatus } from './../_services/ordersState/order.model';

interface IStatusCount {
  name: string;
  count: number;
}
@Component({
  selector: 'app-appmenu',
  templateUrl: './appmenu.component.html',
  styleUrls: ['./appmenu.component.scss'],
})
export class AppmenuComponent implements OnInit {

  statusCount: IStatusCount[] = [];
  active: string;
  internalStatus = internalStatus;

  constructor(
    private ordersService: OrdersService,
    private ordersQuery: OrdersQuery,
    private router: Router,
  ) {

    for (const status of internalStatus) {
      if (status.showMenuCount) {
        this.statusCount.push({
          name: status.name,
          count: 0,
        });
      }
    }

    // set statusCount
    this.ordersQuery.selectAll().subscribe((orders: IOrder[]) => {

      // reset count
      this.statusCount.forEach((status) => status.count = 0);

      for (const order of orders) {
        const status = order.internalStatus;
        const statusIndex = this.statusCount.findIndex((s) => s.name === status);

        if (statusIndex > -1) {
          this.statusCount[statusIndex].count++;
        }
      }
    });

    this.router.events.subscribe((val) => {
      this.active = this.router.routerState.snapshot.url.split('/')[1];
      if (!this.active) {
        this.active = 'orders';
      }
    });
  }

  ngOnInit() {
  }

  getColorForStatus(status: string) {
    return this.internalStatus[this.internalStatus.findIndex((s) => s.name === status)].color;
  }

}
