import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { ID } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { createUser } from '../userState';
import { createClient, IClient } from './client.model';
import { ClientsStore } from './clients.store';

@Injectable()
export class ClientsService {

  constructor(
    private clientsStore: ClientsStore,
    private afStore: AngularFirestore,
  ) {
    this.fetch();
  }

  fetch() {

    this.afStore.collection('clients').snapshotChanges().pipe(
      map((changes) => {
        return changes.map((doc) => {
          const data = doc.payload.doc.data();
          const id = doc.payload.doc.id;
          return { id, ...data };
        });
      }),
    ).subscribe((clients) => {
      // use client model to create correct client
      // this avoids undefined errors
      const c: IClient[] = [];
      for (const client of clients) {
        c.push(createClient(client));
      }
      this.clientsStore.set(c);
    });
  }

  setActive(id: ID) {
    this.clientsStore.setActive(id);
  }

  async addClient(client: IClient) {
    await this.afStore.collection('clients').add(client);
  }

  async editClient(client: IClient) {
    await this.afStore.doc(`clients/${client.id}`).update(client);
  }

  async removeUser(userId: ID, clientId: ID) {
    //
    // steps to take
    // 1. remove workingAt from client
    // 2. update roles for client by removing userId from owner (revoke access)
    // 3. write all changes to firestore
    //
    try {

      await this.afStore.firestore.runTransaction(async (transaction: firebase.firestore.Transaction) => {

        // 1. remove workingAt from client

        // get user document
        const userRef = this.afStore.doc(`users/${userId}`).ref;
        const userDoc = await transaction.get(userRef);

        if (!userDoc.exists) {
          throw new Error('User document does not exist!');
        }

        // get current working at
        const _workingAt = createUser(userDoc.data()).workingAt;
        // filter out the clientId
        const workingAt = _workingAt.filter((cid) => cid !== clientId);

        // 2. update roles for client by removing userId from owner (revoke access)
        const rolesRef = this.afStore.doc(`roles/${clientId}`).ref;
        const rolesDoc = await transaction.get(rolesRef);

        if (!rolesDoc.exists) {
          throw new Error('Roles document does not exist!');
        }

        // get current owners
        const _owner = rolesDoc.data().owner;
        // filter out the userId
        const owner = _owner.filter((uid) => uid !== userId);

        // 3. write all changes to firestore
        transaction.set(userRef, { workingAt }, { merge: true });
        transaction.set(rolesRef, { owner }, { merge: true });

      });

      return 'success';

    } catch (err) {

      console.log(err);
      return 'error';

    }
  }
}
