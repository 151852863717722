import { ID } from '@datorama/akita';

export interface IProduct {
  id?: ID;
  number: ID;
  name: string;
  type: 'Bulk' | 'Zakgoed';
  supplier?: ISupplier;
}

interface ISupplier {
  id: ID;
  name: string;
}

export function createProduct(params: Partial<IProduct>) {
  return {

    id: params.id,
    number: params.number,
    name: params.name,
    type: params.type,
    supplier: params.supplier ? createSupplier(params.supplier) : {},

  } as IProduct;
}

function createSupplier(params: Partial<ISupplier>) {
  return {
    id: params.id,
    name: params.name,
  };
}
