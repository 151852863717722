import { ID } from '@datorama/akita';

export interface IClient {
  id?: ID;
  name: string;
  number: string;
  brs: string;
  contact: IClientContact;
  address: IClientAddress;
  orderCount?: number;
  generalExcelComment?: string;
}

export interface IClientContact {
  name: string;
  email: string;
  phoneNr: string;
}

interface IClientAddress {
  streetName: string;
  streetNumber: string;
  postalCode: string;
  city: string;
}

export function createClient(params: Partial<IClient>) {
  return {
    id: params.id,
    name: params.name,
    number: params.number,
    brs: params.brs || '',
    contact: createClientContact(params.contact) || {},
    address: createClientAddress(params.address) || {},
    orderCount: params.orderCount || 0,
    generalExcelComment: params.generalExcelComment || '',
  } as IClient;
}

function createClientContact(params: Partial<IClientContact>) {
  return {
    name: params.name,
    email: params.email,
    phoneNr: params.phoneNr,
  } as IClientContact;
}

function createClientAddress(params: Partial<IClientAddress>) {
  return {
    streetName: params.streetName,
    streetNumber: params.streetNumber,
    postalCode: params.postalCode,
    city: params.city,
  } as IClientAddress;
}
