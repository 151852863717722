import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { resetStores } from '@datorama/akita';
import { Observable } from 'rxjs';
import { createUser, IUser, IUserState, UserQuery, UserService } from '../userState';

@Injectable()
export class AuthService {

  user$: Observable<IUserState | null>;
  authState: firebase.User = null;

  // Returns true if user is logged in
  get authenticated(): boolean {
    return this.authState !== null;
  }

  constructor(
    public afAuth: AngularFireAuth,
    private afStore: AngularFirestore,
    private userQuery: UserQuery,
    private userService: UserService,
    private router: Router,
  ) {

    this.user$ = this.userQuery.select();

    this.afAuth.authState.subscribe((user) => {
      this.authState = user;
    });

  }

  async signInWithEmail(credentials) {
    try {
      const docRef = this.afStore.collection('roles').doc('adminFrontEndCheck').ref;
      const result = await this.afAuth.auth.signInWithEmailAndPassword(credentials.email, credentials.password);

      // check if user has admin rights
      const doc = await docRef.get();
      if (doc.exists) {
        // use can read document, hence, user has admin rights
        return result;
      } else {
        return {code: 'permission-denied'};
      }
    } catch (error) {
      return error;
    }
  }

  async signUpWithEmail(credentials) {
    try {
      const credential = await this.afAuth.auth.createUserWithEmailAndPassword(credentials.email, credentials.password);

      // send email verification mail
      // TODO: verification needed?
      // this.sendVerificationMail();

      const user: IUser = {
        id: credential.user.uid,
        name: credentials.name,
        email: credentials.email,
      };

      // create user document in firestore
      await this.userService.createAccount(createUser(user));

      return this.afAuth.auth.currentUser;
    } catch (error) {
      return error;
    }
  }

  // send email verification when new user signs in
  async sendVerificationMail() {
    await this.afAuth.auth.currentUser.sendEmailVerification();
  }

  async logout(withRefresh = false) {
    await this.afAuth.auth.signOut();
    // go back to root
    await this.router.navigate(['login'], {replaceUrl: true});

    if (withRefresh) {
      window.location.reload();
    }

    // reset stores
    resetStores();
  }

  async resetPassword(email: string) {
    try {
      return await this.afAuth.auth.sendPasswordResetEmail(email);
    } catch (err) {
      return err;
    }

  }

}
