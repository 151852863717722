import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Alert, AlertType } from './alert.model';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<Alert>();
    private keepAfterRouteChange = true;

    constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                } else {
                    // clear alert messages
                    this.clear();
                    this.keepAfterRouteChange = true;
                }
            }
        });
    }

    // enable subscribing to alerts observable
    onAlert(alertId?: string): Observable<Alert> {
        return this.subject.asObservable().pipe(filter((x) => x && x.alertId === alertId));
    }

    // tslint:disable-next-line: no-any
    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(header: string, message: string, keepAfterRouteChange = true, alertId?: string) {
        this.alert(new Alert({ title: header, message, type: AlertType.Success, keepAfterRouteChange, alertId}));
    }

    error(header: string, message: string, keepAfterRouteChange = true, alertId?: string) {
        this.alert(new Alert({ title: header, message, type: AlertType.Error, keepAfterRouteChange, alertId}));
    }

    warning(header: string, message: string, keepAfterRouteChange = true, alertId?: string) {
        this.alert(new Alert({ title: header, message, type: AlertType.Warning, keepAfterRouteChange, alertId}));
    }

    info(header: string, message: string, keepAfterRouteChange = true, alertId?: string) {
        this.alert(new Alert({ title: header, message, type: AlertType.Info, keepAfterRouteChange, alertId}));
    }

    alert(alert: Alert) {
        this.keepAfterRouteChange = alert.keepAfterRouteChange;
        this.subject.next(alert);
    }

    // clear alerts
    clear(alertId?: string) {
        this.subject.next(new Alert({ alertId }));
    }
}
