import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IOrder } from './order.model';

export interface IOrdersState extends EntityState<IOrder>, ActiveState {}

const initalState = {
  active: null,
};

@StoreConfig({ name: 'orders' })
export class OrdersStore extends EntityStore<IOrdersState, IOrder> {

  constructor() {
    super(initalState);
  }

}
