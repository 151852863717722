import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IOrder } from './order.model';
import { IOrdersState, OrdersStore } from './orders.store';

@Injectable()
export class OrdersQuery extends QueryEntity<IOrdersState, IOrder> {

  constructor(protected store: OrdersStore) {
    super(store);
  }

}
