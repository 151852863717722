import { Component, OnInit } from '@angular/core';
import { AuthService } from '../_services/firebase/auth.service';
import { IUser, IUserState, UserQuery, UserService } from '../_services/userState';

@Component({
  selector: 'app-appheader',
  templateUrl: './appheader.component.html',
  styleUrls: ['./appheader.component.scss'],
})
export class AppheaderComponent implements OnInit {

  user: IUser;

  constructor(
    private auth: AuthService,
    private userService: UserService,
    private userQuery: UserQuery,
  ) {

    this.userQuery.select().subscribe((state: IUserState) => {

      if (state.userDetails) {
        this.user = state.userDetails;
      }
    });
  }

  ngOnInit() {
  }

  logout() {
    this.auth.logout(true);
  }

}
