import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { IUserState, UserStore } from './user.store';

@Injectable()
export class UserQuery extends Query<IUserState> {

  constructor(protected store: UserStore) {
    super(store);
  }

}
