import { ID } from '@datorama/akita';

export interface ISupplier {
  id?: ID;
  name: string;
  number: string;
  email: string;
  phoneNr: string;
  address?: ISupplierAddress;
  contact?: ISupplierContact;
}

interface ISupplierAddress {
  streetName: string;
  streetNumber: string;
  postalCode: string;
  city: string;
}

interface ISupplierContact {
  name: string;
  phoneNr: string;
}

export function createSupplier(params: Partial<ISupplier>) {
  return {

    id: params.id,
    name: params.name,
    number: params.number,
    email: params.email,
    phoneNr: params.phoneNr,
    address: params.address ? createAddress(params.address) : {},
    contact: params.contact ? createContact(params.contact) : {},

  } as ISupplier;
}

function createAddress(params: Partial<ISupplierAddress>) {
  return {

    streetName: params.streetName,
    streetNumber: params.streetNumber || '',
    postalCode: params.postalCode,
    city: params.city,
  } as ISupplierAddress;
}

function createContact(params: Partial<ISupplierContact>) {
  return {
    name: params.name,
    phoneNr: params.phoneNr,
  } as ISupplierContact;
}
