import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IProduct } from './product.model';
import { IProductsState, ProductsStore } from './products.store';

@Injectable()
export class ProductsQuery extends QueryEntity<IProductsState, IProduct> {

  constructor(protected store: ProductsStore) {
    super(store);
  }

}
