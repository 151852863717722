import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { ISupplier } from './supplier.model';

export interface ISuppliersState extends EntityState<ISupplier>, ActiveState {}

const initalState = {
  active: null,
};
@StoreConfig({ name: 'suppliers' })
export class SuppliersStore extends EntityStore<ISuppliersState, ISupplier> {

  constructor() {
    super(initalState);
  }

}
