import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ID } from '@datorama/akita';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { createOrder, internalStatus, IOrder } from './order.model';
import { OrdersQuery } from './orders.query';
import { OrdersStore } from './orders.store';

@Injectable()
export class OrdersService {

  public caOrCoCities$: Observable<string[]>;

  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  get pastWeek() {
    const beginningDate = Date.now() - (60 * 60 * 24 * 7 * 1000); // subtrack one week in miliseconds§
    return new Date(beginningDate);
  }

  constructor(
    private ordersStore: OrdersStore,
    private ordersQuery: OrdersQuery,
    private afStore: AngularFirestore,
  ) {

    this.fetch();

  }

  fetch() {

    //
    // Fetch all orders with correct InternalState
    // We need to make sure that we do not miss an order
    // Only last 'Done' orders should be limited to 15 including updated at !?
    //

    // Query all Internal Status which need to be included
    const refs = [];
    for (const status of internalStatus) {
      if (status.alwaysShow) {
        refs.push(
          this.afStore.collectionGroup('orders', (ref) => ref.where('internalStatus', '==', status.name)),
        );
      } else {
        refs.push(
          this.afStore.collectionGroup('orders', (ref) => ref.where('internalStatus', '==', status.name)
            .where('updatedAt', '>=', this.pastWeek),
          ),
        );
      }
    }

    const combo = combineLatest(...refs.map((ref) => ref.snapshotChanges())).pipe(
      map((status) => {
        return status.map((changes) => {
          return changes.map((order) => {
            const data = order.payload.doc.data();
            const id = order.payload.doc.id;
            // add clientId for reference
            const clientId = order.payload.doc.ref.parent.parent.id;
            return { id, clientId, ...data };
          });
        });
      }),
    );

    combo.subscribe((status) => {
      const _orders: IOrder[] = [];
      for (const orders of status) {
        for (const order of orders) {
          _orders.push(createOrder(order));
        }
      }

      this.ordersStore.set(_orders);
    });

  }

  setActive(id: ID) {
    this.ordersStore.setActive(id);
  }

  async updateInternalStatus(status: string) {

    try {

      if (this.ordersQuery.hasActive()) {

        const activeOrder = this.ordersQuery.getActive();
        await this.afStore.doc(`clients/${activeOrder.clientId}/orders/${activeOrder.id}`)
          .set({ internalStatus: status, updatedAt: this.timestamp }, { merge: true });
      }

    } catch (err) {
      console.log(err);
    }
  }

}
