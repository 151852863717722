import { ID } from '@datorama/akita';

export interface IUser {
  id: ID;
  name: string;
  email: string;
  workingAt?: ID[];
  shoppingCart?: ICart;
}

export interface ICart {
  locations: ICartLocation[];
  deliveryRange: {
      start: string; // Date? Timestamp?
      end: string; // Date? Timestamp?
  };
  comment?: string;
}

interface ICartProduct {
  id: ID;
  name: string;
  type: string;
  siloNumber: string;
  amount: number;
  comment?: string;
}

export interface ICartLocation {
  id: ID;
  clientId: ID;
  name: string;
  address: {};
  products: ICartProduct[];
}

export function createUser(params: Partial<IUser>) {
  return {
    id: params.id,
    name: params.name,
    email: params.email,
    workingAt: params.workingAt || [],
    shoppingCart: createCart(params.shoppingCart) || undefined,
  } as IUser;
}

export function createCart(params: Partial<ICart>) {

  if (!params) {
    // create empty cart
    return {
      locations: [],
      deliveryRange: {
        start: '', // datetime?
        end: '', // datetime?
      },
      comment: '',
    };
  }

  return {
      locations: createCartLocations(params.locations) || [],
      deliveryRange: params.deliveryRange || {},
      comment: params.comment || '',
  } as ICart;

}

function createCartLocations(params: Partial<ICartLocation[]>) {
  const cl: ICartLocation[] = [];
  if (params) {
      for (const location of params) {
          cl.push({
              id: location.id,
              clientId: location.clientId,
              name: location.name,
              address: location.address,
              products: createCartProduct(location.products),
          });
      }
  }
  return cl;
}

function createCartProduct(params: Partial<ICartProduct[]>) {
  const cp: ICartProduct[] = [];
  if (params) {
      for (const product of params) {
          cp.push({
              id: product.id,
              name: product.name,
              type: product.type,
              siloNumber: product.siloNumber || '', // '' when type is zakgoed
              amount: product.amount,
              comment: product.comment || '',
          });
      }
  }
  return cp;
}
