import { Component } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { akitaConfig } from '@datorama/akita';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {

  loggedIn = false;

  constructor(
    private afAuth: AngularFireAuth,
  ) {

    this.afAuth.authState.subscribe((user) => {
      if (!user) {
        this.loggedIn = false;
      } else {
        this.loggedIn = true;
      }
    });

    // make akita stores resettable (used for logout only)
    akitaConfig({
      resettable: true,
    });
  }
}
