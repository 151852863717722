import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IProduct } from './product.model';

export interface IProductsState extends EntityState<IProduct>, ActiveState {}

const initalState = {
  active: null,
};

@StoreConfig({ name: 'products' })
export class ProductsStore extends EntityStore<IProductsState, IProduct> {

  constructor() {
    super(initalState);
  }

}
