import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

import { ID } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { createSupplier, ISupplier } from './supplier.model';
import { SuppliersStore } from './suppliers.store';

@Injectable()
export class SuppliersService {

  constructor(
    private suppliersStore: SuppliersStore,
    private afStore: AngularFirestore,
  ) {

    this.fetch();
  }

  fetch() {

    this.afStore.collection('suppliers').snapshotChanges().pipe(
      map((changes) => {
        return changes.map((doc) => {
          const id = doc.payload.doc.id;
          const data = doc.payload.doc.data();
          return { id, ...data };
        });
      }),
    ).subscribe((suppliers) => {
      // use createSupplier to avoid errors
      const s: ISupplier[] = [];
      for (const supplier of suppliers) {
        s.push(createSupplier(supplier));
      }
      // set store
      this.suppliersStore.set(s);
    });
  }

  setActive(supplierId: ID) {
    this.suppliersStore.setActive(supplierId);
  }

  removeActive(supplierId: ID) {
    this.suppliersStore.removeActive(supplierId);
  }

  async addSupplier(supplier: ISupplier) {
    await this.afStore.collection('suppliers').add(supplier);
  }

  async update(supplier: ISupplier, needToUpdateName = false) {

    let result = 'success';
    // need to also update supplier names if this changes for products
    if (needToUpdateName) {

      // update supplier names in products
      result = await this._updateSupplierNamesinProducts(supplier);

    }

    try {

      if (result === 'success') {
        await this.afStore.doc(`suppliers/${supplier.id}`).update(supplier);
      }

    } catch (err) {

      console.log('err', err);

    }

  }

  private async _updateSupplierNamesinProducts(supplier: ISupplier) {

    try {
      await this.afStore.firestore.runTransaction(async (transaction) => {

        // TODO: [VIT-230] location product names are not updated properly

        // get all product which matches the changes to current supplier
        const snapshot = await this.afStore.collection(`products`,
          (ref) => ref.where('supplier.id', '==', supplier.id)).get().toPromise();

        snapshot.forEach((doc) => {

          // update supplier name in products
          transaction.update(doc.ref, {
            supplier: {
              id: supplier.id,
              name: supplier.name,
            },
          });

        });

      });

      return 'success';

    } catch (err) {

      return 'error';
    }

  }

}
