import { ID } from '@datorama/akita';

export interface IOrder {
  id?: ID;
  orderId: string; // client.number + increment of orderCount
  clientId: ID;
  clientName: string;
  locations: IOrderLocation[];
  deliveryRange: {
    start: firebase.firestore.Timestamp,
    end: firebase.firestore.Timestamp,
  };
  comment?: string;
  createdBy?: ICreatedBy;
  createdAt?: firebase.firestore.Timestamp;
  updatedAt?: firebase.firestore.Timestamp;
  internalStatus?: string;
}

interface ICreatedBy {
  id: ID;
  name: string;
}
interface IOrderLocation {
  id: ID;
  clientId: string;
  address: {};
  name: string;
  ubn: string;
  products: IOrderProduct[];
}

interface IOrderProduct {
  id: ID;
  amount: number;
  name: string;
  siloNumber: number;
  type: 'bulk' | 'zakgoed';
  comment?: string;
}

export const internalStatus = [
  {
    name: 'Nieuw',
    color: '#00b8a9',
    showMenuCount: true,
    alwaysShow: true,
  },
  {
    name: 'Verzonden naar leverancier',
    color: '#F9B800',
    showMenuCount: true,
    alwaysShow: true,
  },
  {
    name: 'Bevestiging van leverancier',
    color: 'rgb(255, 215, 104)',
    showMenuCount: true,
    alwaysShow: true,
  },
  {
    name: 'Afleverbon ontvangen',
    color: '#00afea',
    showMenuCount: false,
    alwaysShow: true,
  },
  {
    name: 'Inkoopfactuur ontvangen',
    color: '#42c6f3',
    showMenuCount: false,
    alwaysShow: true,
  },
  {
    name: 'Concept factuur gemaakt',
    color: '#D08944',
    showMenuCount: false,
    alwaysShow: true,
  },
  {
    name: 'Verkoopfactuur gemaakt',
    color: '#dea369',
    showMenuCount: false,
    alwaysShow: false,
  },
  {
    name: 'Geannuleerd',
    color: '#2d4059',
    showMenuCount: false,
    alwaysShow: false,
  },
];

export function createOrder(params: Partial<IOrder>) {
  return {
    id: params.id,
    orderId: params.orderId,
    clientId: params.clientId,
    clientName: params.clientName,
    locations: params.locations || [],
    deliveryRange: params.deliveryRange,
    comment: params.comment || '',
    createdAt: params.createdAt || '',
    createdBy: params.createdBy || {},
    internalStatus: params.internalStatus || 'Nieuw', // if no internalStatus, then its a new order
  } as IOrder;
}
