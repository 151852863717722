import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { IClient } from './client.model';
import { ClientsStore, IClientsState } from './clients.store';

@Injectable()
export class ClientsQuery extends QueryEntity<IClientsState, IClient> {

  constructor(protected store: ClientsStore) {
    super(store);
  }

}
