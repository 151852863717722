import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, take, tap } from 'rxjs/operators';
import { AuthService } from '../_services/firebase/auth.service';
import { UserService } from '../_services/userState';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private afAuth: AngularFireAuth,
    private auth: AuthService,
    private userService: UserService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (this.auth.authenticated) {
      return true;
    }

    return this.afAuth.user.pipe(
      take(1),
      map((user) => !!user),
      tap((loggedIn) => {
        if (!loggedIn) {
          // access denied
          this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        }
      }),
    );
  }

}
