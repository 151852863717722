import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { createUser, ICart, ICartLocation, IUser } from './user.model';
import { UserQuery } from './user.query';
import { UserStore } from './user.store';

@Injectable()
export class UserService {

  user$: Observable<IUser>;
  private userDocument: AngularFirestoreDocument<IUser>;

  constructor(
    private userStore: UserStore,
    private afStore: AngularFirestore,
    private afAuth: AngularFireAuth,
    private userQuery: UserQuery,
  ) {

    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          this.userDocument = this.afStore.doc<IUser>(`users/${user.uid}`);
          return this.afStore.doc<IUser>(`users/${user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      }),
    );
    this.fetch();
  }

  fetch() {
    this.user$.subscribe((user: IUser) => {
      if (user) {
        this.userStore.update({ userDetails: createUser(user) });
      } else { // logout: creates a client = null
        this.userStore.destroy();
      }
    });
  }

  async createAccount(user: IUser) {
    await this.afStore.doc<IUser>(`users/${user.id}`).set(user);
  }

  async addToCart(cartLocation: ICartLocation) {

    const userId = this.userQuery.getValue().userDetails.id;
    const _shoppingCart: ICart = _.cloneDeep(this.userQuery.getValue().userDetails.shoppingCart);

    // check if we already have products for the location
    // get index of existing location in the shipping cart
    const existingLocationIndex = _shoppingCart.locations.findIndex((location) => {
      return location.id === cartLocation.id;
    });

    if (existingLocationIndex !== -1) {

      // append to existing location
      for (const product of cartLocation.products) {
        _shoppingCart.locations[existingLocationIndex].products.push(product);
      }

    } else {

      // append a new location
      _shoppingCart.locations.push(cartLocation);

    }

    // update firestore document with new cart
    await this.afStore.doc<IUser>(`users/${userId}`).update({ shoppingCart: _shoppingCart });

  }

  async emptyShoppingCart() {
    const userId = this.userQuery.getValue().userDetails.id;
    await this.afStore.doc<IUser>(`users/${userId}`).update({ shoppingCart: null });
  }
}
