import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AlertModule } from './_services/alert/alert.module';

import { AuthGuard } from './_guards/auth.guard';
import { ClientsQuery, ClientsService, ClientsStore } from './_services/clientState';
import { AuthService } from './_services/firebase/auth.service';
import { OrdersQuery, OrdersService, OrdersStore } from './_services/ordersState';
import { ProductsQuery, ProductsService, ProductsStore } from './_services/productState';
import { SuppliersQuery, SuppliersService, SuppliersStore } from './_services/suppliersState';
import { UserQuery, UserService, UserStore } from './_services/userState';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppfooterComponent } from './appfooter/appfooter.component';
import { AppheaderComponent } from './appheader/appheader.component';
import { AppmenuComponent } from './appmenu/appmenu.component';

@NgModule({
  declarations: [
    AppComponent,
    AppheaderComponent,
    AppmenuComponent,
    AppfooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireAuthModule,
    AlertModule,
  ],
  providers: [
    AuthGuard,
    AuthService,
    UserService,
    UserStore,
    UserQuery,
    SuppliersQuery,
    SuppliersService,
    SuppliersStore,
    ProductsQuery,
    ProductsService,
    ProductsStore,
    OrdersService,
    OrdersQuery,
    OrdersStore,
    ClientsService,
    ClientsQuery,
    ClientsStore,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
