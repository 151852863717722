import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IClient } from './client.model';

export interface IClientsState extends EntityState<IClient>, ActiveState {}

const initalState = {
  active: null,
};
@StoreConfig({ name: 'clients' })
export class ClientsStore extends EntityStore<IClientsState, IClient> {

  constructor() {
    super(initalState);
  }

}
