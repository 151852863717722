// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  version: '1.1.0-dev',
  firebase: {
    apiKey: 'AIzaSyADHeMeuE4V0E0x1ImIMn9YJkZYEng0IBE',
    authDomain: 'dev-vitalve-77608.firebaseapp.com',
    databaseURL: 'https://dev-vitalve-77608.firebaseio.com',
    projectId: 'dev-vitalve-77608',
    storageBucket: 'dev-vitalve-77608.appspot.com',
    messagingSenderId: '545461893830',
    appId: '1:545461893830:web:cac503f3dc086f8b',
  },
  baseUrlApp: 'http://dev-bestellen.firebaseapp.com', // bestel app base url
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
