import { ISilo } from '@/app/clients/client-detail/_locationsState';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ID } from '@datorama/akita';
import { map, tap } from 'rxjs/operators';
import { createProduct, IProduct } from './product.model';
import { ProductsStore } from './products.store';

@Injectable()
export class ProductsService {

  constructor(
    private productsStore: ProductsStore,
    private afStore: AngularFirestore,
  ) {

    this.fetch();

  }

  fetch() {

    this.afStore.collection('products').snapshotChanges().pipe(
      map((changes) => {
        return changes.map((doc) => {
          const id = doc.payload.doc.id;
          const data = doc.payload.doc.data();
          return { id, ...data };
        });
      }),
    ).subscribe((products) => {

      // use createProducts to avoid errors
      const prod: IProduct[] = [];
      for (const product of products) {
        prod.push(createProduct(product));
      }

      // set store
      this.productsStore.set(prod);
    });
  }

  setActive(productId: ID) {
    this.productsStore.setActive(productId);
  }

  async addProduct(product: IProduct) {
    await this.afStore.collection('products').add(product);
  }

  async remove(product: IProduct) {
    // remove product from all locations where product is active
    // and remove alswer
    return this._removeProduct(product);
  }

  async update(product: IProduct) {

    // update all locations that have this product
    const result = await this._updateLocationsWithNewProduct(product);

    if (result === 'success') {

      try {

        await this.afStore.doc(`products/${product.id}`).update(product);

      } catch (err) {

        console.log('err', err);
      }
    }

    return result;
  }

  private async _updateLocationsWithNewProduct(product: IProduct) {

    try {

      await this.afStore.firestore.runTransaction(async (transaction) => {

        // get all locations which matches the changes to current product
        // TODO: [VIT-231] change query to reduce reads if there are many locations
        const snapshot = await this.afStore.collectionGroup('locations').get().toPromise();

        snapshot.forEach((doc) => {

          if (doc.data().products) {

            // get all products from location
            const _products: IProduct[] = doc.data().products;
            // remove product that needs to be updated
            const products = _products.filter((p) => p.id !== product.id);

            // if length are not equal, product needs to be updated
            if (_products.length !== products.length) {

              // add updated product to products
              products.push(createProduct(product));
              // update firestore
              transaction.update(doc.ref, { products });

            }
          }

        });

      });

      return 'success';

    } catch (err) {

      console.log(err);
      return 'error';
    }

  }

  private async _removeProduct(product: IProduct) {

    try {

      await this.afStore.firestore.runTransaction(async (transaction) => {

        // get all locations which contain current product
        // TODO: [VIT-231] change query to reduce reads if there are many locations
        const snapshot = await this.afStore.collectionGroup('locations').get().toPromise();

        // get product document
        const productRef = this.afStore.doc(`products/${product.id}`).ref;

        snapshot.forEach((doc) => {

          if (doc.data().products) {

            // get all products an siloSettings from location
            const _products: IProduct[] = doc.data().products;
            let _siloSettings: ISilo[] = [];

            if (doc.data().siloSettings) {
              _siloSettings = doc.data().siloSettings;
            }
            // remove product
            const products = _products.filter((p) => p.id !== product.id);
            const siloSettings = _siloSettings.filter((s) => s.product.id !== product.id);
            // if length are not equal, product needs to be updated
            if (_products.length !== products.length) {

              // update firestore without old product (excluding also products in silo)
              transaction.update(doc.ref, { products, siloSettings });

            }

          }

        });

        // delete product itself
        transaction.delete(productRef);

      });

      return 'success';

    } catch (err) {

      console.log(err);
      return 'error';
    }

  }

}
